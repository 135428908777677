import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';

export class LockVueErrorHandler extends SentryErrorHandler {
  constructor(private config: ErrorHandlerOptions) {
    super(config);
  }

  override handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    super.handleError(error);
  }
}
