import { Component, Input, OnInit } from '@angular/core';
import { ApiKey, ApiKeysService } from '../../lockvue-ng-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessagesService } from '../../services/notification-messages.service';

@Component({
  selector: 'lockvue-view-key',
  templateUrl: './view-key.component.html',
  styleUrls: ['./view-key.component.scss'],
})
export class ViewKeyComponent {
  constructor(
    private apiKeyService: ApiKeysService,
    private notificationService: NotificationMessagesService,
  ) {}
  @Input() value: string;

  @Input() rowData: ApiKey;

  public showApi: boolean = false;
  public actualKey$ = new BehaviorSubject<string>('');
  public loading$ = new BehaviorSubject<boolean>(false);

  toggleApiKey() {
    this.actualKey$.next('');
    this.showApi = !this.showApi;
    if (this.showApi) {
      this.loading$.next(true);
      this.apiKeyService.apiKeysGetActualApiKey(this.rowData.Id).subscribe(
        key => {
          this.actualKey$.next(key);
          this.loading$.next(false);
        },
        error => {
          this.notificationService.showError(
            'Error',
            `Something went wrong getting the key! `,
            error.error,
          );
        },
      );
    }
  }
}
