import { Injectable } from '@angular/core';
import { NbRoleProvider } from '@nebular/security';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class RoleProvider implements NbRoleProvider {
  constructor(private oidcSecurity: OidcSecurityService) {}

  getRole(): Observable<string> {
    return this.oidcSecurity.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        return isAuthenticated
          ? this.oidcSecurity.getUserData().pipe(map(data => data.roles))
          : of('guest');
      }),
    );
  }
}
