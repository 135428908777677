import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lockvue-battery-level',
  templateUrl: './battery-level.component.html',
  styleUrls: ['./battery-level.component.scss'],
})
export class BatteryLevelComponent {
  // Battery level percentage
  _batteryPercent: number;

  @Input() set value(v: any) {
    this._batteryPercent = parseInt(v, 10);
  }

  @Input() showValue: boolean = true;

  constructor() {}

  getText(): string {
    return isNaN(this._batteryPercent)
      ? 'Unknown'
      : this._batteryPercent === 256
      ? 'Charging'
      : this._batteryPercent + '%';
  }

  getBatteryClass(): string {
    if (isNaN(this._batteryPercent)) {
      return '';
    }

    if (this._batteryPercent === 256) {
      return 'lv-battery-high ion-battery-charging';
    }
    if (this._batteryPercent < 15) {
      return 'lv-battery-low ion-battery-empty';
    } else if (this._batteryPercent < 30) {
      return 'lv-battery-low ion-battery-low';
    } else if (this._batteryPercent < 90) {
      return 'lv-battery-high ion-battery-half';
    } else {
      return 'lv-battery-high ion-battery-full';
    }
  }
}
