import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NbAuthComponent } from '@nebular/auth';
import { LogoutComponent } from './auth/components/logout/logout.component';
import { AutoLoginComponent } from './auth/components/auto-login/auto-login.component';
import { RegisterSuccessComponent } from './auth/components/register-success/register-success.component';
import { EmailConfirmedComponent } from './auth/components/email-confirmed/email-confirmed.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { LoginComponent } from './auth/components/login/login.component';
import { AccountResetComponent } from './auth/components/account-reset/account-reset.component';
import { Oauth2CallbackComponent } from './auth/components/oauth2-callback/oauth2-callback.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'callback',
        component: Oauth2CallbackComponent,
      },
      {
        path: '**',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
