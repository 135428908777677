<div *ngIf="large">
  <h2>
    <lockvue-lock-name [value]="value.DisplayName" [maxLength]="35"></lockvue-lock-name>
  </h2>
  <hr />

  <div>
    <span><b>Lock Type: </b></span>
    <span>{{ value.LockType }} ({{ value.Subtype }})</span>
  </div>
  <br />
  <div>
    <span><b>Serial Number: </b></span>
    <span>{{ value.UID }}</span>
  </div>
  <br />
  <div *ngIf="!value.Subtype.includes('KeyRack')">
    <div>
      <span><b>Last Known Battery Level: </b></span>
      &nbsp;
      <lockvue-battery-level [value]="value.LastBatteryLevel" [showValue]="true">
      </lockvue-battery-level>
    </div>
    <br />
  </div>
</div>
<div *ngIf="!large" style="display: inline">
  <span *ngIf="!value">NA</span>
  <span *ngIf="value">
    <img
      class="lock-icon"
      src="https://cdn.lockvue.com/locks/{{ value.LockType | lowercase }}_{{
        value.Subtype | lowercase
      }}_{{ iconColor }}.svg" />
    <lockvue-lock-name class="lock-name" [value]="value.DisplayName"></lockvue-lock-name>
    <small class="text-muted">({{ value.UID }})</small>
  </span>
</div>
