import { Component, Input } from '@angular/core';
import { ApiKey } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-api-key-roles',
  templateUrl: './api-key-roles.component.html',
  styleUrls: ['./api-key-roles.component.scss'],
})
export class ApiKeyRolesComponent {
  @Input() apiKey: ApiKey;
}
